// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "r_q5 d_gQ d_cv d_dt";
export var quoteParagraphCenter = "r_gR d_gR d_cv d_dv";
export var quoteParagraphRight = "r_gS d_gS d_cv d_dw";
export var quoteRowLeft = "r_q6 d_bF";
export var quoteRowCenter = "r_q7 d_bC";
export var quoteRowRight = "r_q8 d_bG";
export var quoteWrapper = "r_gN d_gN d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "r_gP d_gP";
export var quoteExceptionSmall = "r_q9 t_q9";
export var quoteExceptionNormal = "r_rb t_rb";
export var quoteExceptionLarge = "r_rc t_rc";
export var quoteAuthorExceptionSmall = "r_rd t_rd";
export var quoteAuthorExceptionNormal = "r_rf t_rf";
export var quoteAuthorExceptionLarge = "r_rg t_rg";