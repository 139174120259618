// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qc d_fn d_bF d_dt";
export var alignLeft = "p_qd d_fn d_bF d_dt";
export var alignDiscCenter = "p_qf d_fp d_bC d_dv";
export var alignCenter = "p_bN d_fp d_bC d_dv";
export var alignDiscRight = "p_qg d_fq d_bG d_dw";
export var alignRight = "p_qh d_fq d_bG d_dw";
export var footerContainer = "p_qj d_dV d_bV";
export var footerContainerFull = "p_qk d_dS d_bV";
export var footerHeader = "p_kc d_kc";
export var footerTextWrapper = "p_ql d_v";
export var footerDisclaimerWrapper = "p_kk d_kk d_cj";
export var badgeWrapper = "p_qm d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "p_qn undefined";
export var footerDisclaimerLeft = "p_qp undefined";
export var verticalTop = "p_qq d_by d_bH d_bM d_bK";
export var firstWide = "p_qr";
export var disclaimer = "p_qs d_by d_bH";
export var socialDisclaimer = "p_qt";
export var left = "p_qv";
export var wide = "p_qw d_cx";
export var right = "p_qx d_bJ";
export var line = "p_fj d_fk d_ct";
export var badgeDisclaimer = "p_qy d_bB d_bN d_bH";
export var badgeContainer = "p_qz d_by d_bG d_bN";
export var badge = "p_qB";
export var padding = "p_qC d_c6";
export var end = "p_qD d_bG";
export var linkWrapper = "p_qF d_dz";
export var link = "p_mz d_dz";
export var colWrapper = "p_qG d_cw";
export var consent = "p_f d_f d_bB d_bN";
export var media = "p_qH d_bw d_dx";
export var itemRight = "p_qJ";
export var itemLeft = "p_qK";
export var itemCenter = "p_qL";
export var exceptionWeight = "p_qM t_rV";