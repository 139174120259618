// extracted by mini-css-extract-plugin
export var customText = "n_p5 d_dt d_cr d_cf";
export var videoIframeStyle = "n_pG d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "n_p6 d_cr d_cf d_Y";
export var customRow = "n_p0 d_bC d_bd";
export var quoteWrapper = "n_gN d_bB d_bN d_cr d_cf";
export var quoteBar = "n_pL d_G";
export var masonryImageWrapper = "n_pS";
export var title = "n_p7";
export var Title3Small = "n_p8 t_p8 t_rs t_rB";
export var Title3Normal = "n_p9 t_p9 t_rs t_rC";
export var Title3Large = "n_qb t_qb t_rs t_rD";