// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qN d_gs d_cr";
export var heroHeaderCenter = "q_gt d_gt d_cr d_dv";
export var heroHeaderRight = "q_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "q_qP d_gp d_cv";
export var heroParagraphCenter = "q_gq d_gq d_cv d_dv";
export var heroParagraphRight = "q_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "q_qQ d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "q_qR d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "q_qS d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "q_qT d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "q_qV d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "q_qW t_qW";
export var heroExceptionNormal = "q_qX t_qX";
export var heroExceptionLarge = "q_qY t_qY";
export var Title1Small = "q_qZ t_qZ t_rs t_rt";
export var Title1Normal = "q_q0 t_q0 t_rs t_rv";
export var Title1Large = "q_q1 t_q1 t_rs t_rw";
export var BodySmall = "q_q2 t_q2 t_rs t_rM";
export var BodyNormal = "q_q3 t_q3 t_rs t_rN";
export var BodyLarge = "q_q4 t_q4 t_rs t_rP";