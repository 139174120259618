// extracted by mini-css-extract-plugin
export var dark = "y_s5";
export var darkcookie = "y_s6";
export var tintedcookie = "y_s7";
export var regularcookie = "y_s8";
export var darkbase = "y_s9";
export var tintedbase = "y_tb";
export var regularbase = "y_tc";
export var darkraw = "y_td";
export var tintedraw = "y_tf";
export var regularraw = "y_tg";
export var darkchick = "y_th";
export var tintedchick = "y_tj";
export var regularchick = "y_tk";
export var darkherbarium = "y_tl";
export var tintedherbarium = "y_tm";
export var regularherbarium = "y_tn";
export var darkholiday = "y_tp";
export var tintedholiday = "y_tq";
export var regularholiday = "y_tr";
export var darkoffline = "y_ts";
export var tintedoffline = "y_tt";
export var regularoffline = "y_tv";
export var darkorchid = "y_tw";
export var tintedorchid = "y_tx";
export var regularorchid = "y_ty";
export var darkpro = "y_tz";
export var tintedpro = "y_tB";
export var regularpro = "y_tC";
export var darkrose = "y_tD";
export var tintedrose = "y_tF";
export var regularrose = "y_tG";
export var darktimes = "y_tH";
export var tintedtimes = "y_tJ";
export var regulartimes = "y_tK";
export var darkwagon = "y_tL";
export var tintedwagon = "y_tM";
export var regularwagon = "y_tN";