// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "s_rh d_bC";
export var storyRowWrapper = "s_hv d_hv d_bJ";
export var storyLeftWrapper = "s_rj d_by d_bN";
export var storyWrapperFull = "s_rk d_cC";
export var storyWrapperFullLeft = "s_nL d_cC d_by d_bN d_bC";
export var contentWrapper = "s_px d_hw";
export var storyLeftWrapperCenter = "s_rl d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "s_rm d_hC";
export var storyHeader = "s_rn d_hB d_v d_cr";
export var storyHeaderCenter = "s_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "s_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "s_rp d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "s_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "s_rq d_ff d_Y";
export var imageWrapperFull = "s_rr d_v d_G d_bd d_Y";